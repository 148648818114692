import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  capsLetter,
  dateRanges,
  formatDateToMonthDayYear,
  ticketCategoryList,
} from "../../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import MaterialUITable from "../../components/material-ui-table/MaterialUiTable";
import { formatTimeStamp } from "../../utils";
import axios from "axios";
import {
  containsAll,
  get_api,
  removeAllFromArray,
  removeAllItems,
  removeItemOnce,
} from "../../utils/LocalFunctions";
import Pagination from "../../components/pagination/Pagination";
import { formatDateRange } from "../all-chats/AllChats";
import moment from "moment";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";

import { clear_all_svg, filterbydate } from "../../utils/SvgIcons";
import { render_category_remarks } from "./AgentAssignedTickets";

import { message, Select } from "antd";
import EndChatModal from "../../components/modals/end-chat-modal/EndChatModal";
import TextModal from "../../components/modals/text-modal/TextModal";
import ConfirmationModal1 from "../../components/modals/confirmation-modal/ConfirmationModal1";

export const lightGradients = {
  USER_RESPONDED: "linear-gradient(135deg, #ff8a65 0%, #ffccbc 100%)",
  2: "linear-gradient(135deg, #ff4e50 0%, #f44336 100%)",

  1: "linear-gradient(135deg, #64b5f6 0%, #bbdefb 100%)", // Light Blue
  TICKET_RAISED: "linear-gradient(135deg, #f06292 0%, #f8bbd0 100%)", // Light Pink
  USER_ACTION_REQUIRED: "linear-gradient(135deg, #9575cd 0%, #d1c4e9 100%)", // Light Purple
  ESCALATED: "linear-gradient(135deg, #ffb74d 0%, #ffe0b2 100%)", // Light Orange
  RESOLVED: "linear-gradient(135deg, #fff176 0%, #fff9c4 100%)", // Light Yellow
  DUPLICATE: "linear-gradient(135deg, #b30000 0%, #ff0000 100%)",
  MINIS:"linear-gradient(135deg, #4B164C, #DD88CF)"
};

export default function AllTickets({ setSelectedQueueTicketId }) {
  const [searchParams] = useSearchParams();
  const initializeDateRange = () => {
    const dateRangeParam = searchParams.get("date_range");
    if (dateRangeParam && dateRangeParam !== "") {
      const { start_date, end_date } = formatDateRange(dateRangeParam, "");
      return {
        startDate: start_date || todaysDate,
        endDate: end_date || todaysDate,
      };
    }
    return {
      startDate: "",
      endDate: "",
    };
  };
  const maxDate = moment().format("YYYY-MM-DD");
  const todaysDate = maxDate;
  const [queueTicketsData, setQueueTicketsData] = useState([]);
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false);
  const [isDuplicateDropDownOpen, setIsDuplicateDropDownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("current_page")
      ? parseInt(searchParams.get("current_page"))
      : 1
  );

  const [totalPages, setTotalPages] = useState(
    searchParams.get("current_page") ? searchParams.get("current_page") : 1
  );
  const [initialDateRange, setInitialDaterange] = useState(
    initializeDateRange()
  );
  const [selectedRange, setSelectedRange] = useState(initializeDateRange());
  const navigate = useNavigate();
  const api = get_api();
  const [statusFilter, setStatusFilter] = useState(
    searchParams.get("status_filter")
      ? searchParams.get("status_filter").split(",")
      : ["ESCALATED", "USER_RESPONDED", "PENDING"]
  );

  const [duplicateFilter, setDuplicateFilter] = useState(
    searchParams.get("duplicate_filter")
      ? searchParams.get("duplicate_filter").split(",")
      : []
  );

  const [categoryFilter, setCategoryFilter] = useState(
    searchParams.get("category_filter")
      ? searchParams.get("category_filter").split(",")
      : []
  );

  const [selected_tickets, setSelectedTickets] = useState([]);
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [ticket_message, setTicketMessage] = useState({});

  const [end_chat_modal, setEndChatModal] = useState(false);
  const [send_message_modal, setSendMessageModal] = useState(false);
  const [show_confirmation, setShowConfirmation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [action, setAction] = useState("");
  const [select_all, setSelectAll] = useState(false);
  const agent_id = localStorage.getItem("cms_id");
  const [dateFilterText, setDateFilterText] = useState(() => {
    const dateRangeParam = searchParams.get("date_range");
    if (dateRangeParam && dateRangeParam !== "") {
      return dateRangeParam;
    }
    return "Filter Date Created";
  });

  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  useEffect(() => {
    const { start_date, end_date } = formatDateRange(
      searchParams.get("date_range") ? searchParams.get("date_range") : "",
      ""
    );

    setSelectedRange({ endDate: end_date, startDate: start_date });
    setInitialDaterange({ endDate: end_date, startDate: start_date });
  }, []);

  const get_all_tickets = () => {
    axios
      .get(
        `${api}/user_ticket/?offset=${
          (currentPage - 1) * 50
        }&limit=50&sortBy=-created_at&search_term=${search}&status=${statusFilter}&category=${categoryFilter}&duplicate_filter=${duplicateFilter}&start_date=${
          selectedRange?.startDate !== ""
            ? `${selectedRange?.startDate} 00:00:00`
            : ""
        }&end_date=${
          selectedRange?.endDate !== ""
            ? `${selectedRange?.endDate} 23:59:5`
            : ""
        }`
      )
      .then((res) => {
        setQueueTicketsData(res?.data?.result);
        setTotalPages(res?.data?.total_result_count);
      })
      .catch((e) => {
        console.log(e?.message);
      });
  };

  useEffect(() => {
    get_all_tickets();
  }, [
    currentPage,
    statusFilter,
    selectedRange?.startDate,
    selectedRange?.endDate,
    duplicateFilter,
    categoryFilter,
  ]);

  useEffect(() => {
    const newParams = new URLSearchParams();
    if (dateFilterText !== "Filter Date Created") {
      newParams.set("date_range", dateFilterText);
    }

    if (statusFilter.length > 0) {
      newParams.set("status_filter", statusFilter.join(","));
    }

    if (duplicateFilter.length > 0) {
      newParams.set("duplicate_filter", duplicateFilter.join(","));
    }
    if (categoryFilter.length > 0) {
      newParams.set("category_filter", categoryFilter.join(","));
    }

    if (search.length > 0) {
      newParams.set("search_filter", search);
    }

    newParams.set("current_page", currentPage.toString());

    navigate({
      pathname: window.location.pathname,
      search: newParams.toString(),
    });
  }, [
    statusFilter,
    currentPage,
    dateFilterText,
    duplicateFilter,
    categoryFilter,
    search,
  ]);

  useEffect(() => {
    if (action === "CLOSED") {
      setEndChatModal(true);
    } else if (action === "SEND_MESSAGE") {
      setSendMessageModal(true);
    }
  }, [action]);

  const make_bulk_options_api = (obj, isPatch = false) => {
    if (!isLoading) {
      setIsLoading(true);
      const data =
        action === "CLOSED"
          ? {
              closing_category: obj.closingCategory,
              closing_reason: obj.reason,
              closing_sub_reason: obj.subReason,
              closing_notes: obj.conversationNotes,
              user_message: obj.user_message,
            }
          : {
              user_message: obj.user_message,
            };
      const apiCall = isPatch ? axios.patch : axios.put;
      apiCall(`${api}/bulk_procesing/`, {
        user_ids: selected_tickets,
        action: action,
        filters: {
          status: statusFilter,
          duplicate_filter: duplicateFilter,
          start_date: `${
            selectedRange?.startDate !== ""
              ? `${selectedRange?.startDate} 00:00:00`
              : ""
          }`,
          end_date: `${
            selectedRange?.endDate !== ""
              ? `${selectedRange?.endDate} 23:59:5`
              : ""
          }`,
        },
        agent_id: agent_id,
        ...data,
      })
        .then((res) => {
          if (isPatch) {
            setEndChatModal(false);
            setSendMessageModal(false);
            setShowConfirmation(true);
            setTicketMessage({ message: res.data.message, object: obj });
          }

          if (!isPatch) {
            setShowConfirmation(false);
            get_all_tickets();
            setSelectedTickets([]);
            setAction("");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const render_remarks = (obj) => {
    const filteredKeys = Object.keys(obj).filter(
      (e) => !["RESOLVED", "PENDING","USER_ACTION_REQUIRED","USER_RESPONDED"].includes(e)
    );

    return (
      <div style={{ gap: "8px" }} className="flex-flex-start-center">
        {filteredKeys.slice(0, 1).map((e, index) => (
          <div
            key={index}
            style={{
              background: lightGradients[e]
                ? lightGradients[e]
                : lightGradients[1],
              color: "white",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            {capsLetter(e).replaceAll("_", " ")}
          </div>
        ))}
        {filteredKeys.length > 1 && (
          <div
            style={{
              background: "grey",
              color: "white",
              padding: "5px",
              borderRadius: "4px",
            }}
            className="font-weight-600"
          >
            +{filteredKeys.length - 1}
          </div>
        )}
      </div>
    );
  };

  const changeSelectedRange = (e) => {
    let startDate = moment(new Date(e["startDate"])).format("YYYY-MM-DD");
    let endDate = moment(new Date(e["endDate"])).format("YYYY-MM-DD");
    setSelectedRange({
      startDate: startDate,
      endDate: endDate,
    });
  };

  const saveDateClickHandler = () => {
    setInitialDaterange({
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate,
    });
    setDateFilterText(
      `${formatDateToMonthDayYear(
        selectedRange.startDate
      )} -> ${formatDateToMonthDayYear(selectedRange.endDate)}`
    );
    setIsDateDropdownOpen(false);
  };

  function renderFilterByDateContent(type) {
    return (
      <div
        className="height-100"
        style={{
          marginRight: !type && "10px",
        }}
      >
        <Dropdown
          align={"end"}
          id={
            type
              ? "survey-date-filter-dropdown-daily-chats"
              : "survey-date-filter-dropdown"
          }
          className="survey-date-filter"
          show={isDateDropdownOpen}
          onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle className="width-100 height-100" style={{}}>
            <div className="flex-center-center toggle-text-filter">
              {filterbydate(dateFilterText)}
              {dateFilterText}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            <div className="date-filter-calender-container">
              {isDateDropdownOpen && (
                <DateRangePicker
                  open={isDateDropdownOpen}
                  onChange={(e) => changeSelectedRange(e)}
                  definedRanges={dateRanges}
                  initialDateRange={initialDateRange}
                  maxDate={todaysDate}
                />
              )}

              <div
                style={{ padding: "0px 10px" }}
                className="flex-space-between-center"
              >
                <p
                  onClick={() => {
                    setDateFilterText("Filter Date Created");
                    setIsDateDropdownOpen(false);
                    setInitialDaterange({
                      startDate: todaysDate,
                      endDate: todaysDate,
                    });
                    setSelectedRange({
                      startDate: "",
                      endDate: "",
                    });
                  }}
                  className="flex-flex-start-center clear-all-2C66D7 cursor-p"
                >
                  <span>{clear_all_svg()}</span>
                  Clear All
                </p>
                <p
                  onClick={() => {
                    saveDateClickHandler();
                  }}
                  className="clear-all-2C66D7 cursor-p"
                >
                  Save
                </p>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function filterByDuplicate() {
    return (
      <div>
        <Dropdown
          className="survey-date-filter"
          id="survey-status-filter-dropdown"
          show={isDuplicateDropDownOpen}
          onToggle={(isOpen) => setIsDuplicateDropDownOpen(isOpen)}
        >
          <Dropdown.Toggle>
            <div className="flex-center-center toggle-text-filter">
              {duplicateFilter.length > 0 ? (
                <p className="dot-filter mr-10px"></p>
              ) : null}
              Filter by Remark{" "}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            {clearAllFilters(duplicateFilter, setDuplicateFilter)}
            {renderallDuplicate()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  const renderallStatus = () => {
    let alltypes = [
      "USER_ACTION_REQUIRED",
      "ESCALATED",
      "USER_RESPONDED",
      "PENDING",
      "RESOLVED",
    ];
    return alltypes.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            setCurrentPage(1);
            if (statusFilter.includes(e)) {
              setStatusFilter([...removeItemOnce(statusFilter, e)]);
            } else {
              setStatusFilter([...statusFilter, e]);
            }
          }}
        >
          {statusFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  const renderallCategories = () => {
    return ticketCategoryList.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            setCurrentPage(1);
            if (categoryFilter.includes(e)) {
              setCategoryFilter([...removeItemOnce(categoryFilter, e)]);
            } else {
              setCategoryFilter([...categoryFilter, e]);
            }
          }}
          style={{
            width: "fit-content",
            textWrap: "nowrap",
          }}
        >
          {categoryFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  function clearAllFilters(filters, setFilters) {
    return (
      <p
        className="single-status-filter"
        onClick={() => {
          setCurrentPage(1);

          setFilters([]);
        }}
        style={{
          width: "fit-content",
          textWrap: "nowrap",
        }}
      >
        {filters.length === 0 ? (
          <FontAwesomeIcon className="mr-8px" icon={faCheck}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            className="mr-8px"
            style={{ visibility: "hidden" }}
            icon={faCheck}
          ></FontAwesomeIcon>
        )}
        {"Clear all"}
      </p>
    );
  }

  function filterByCategory() {
    return (
      <div>
        <Dropdown
          className="survey-date-filter"
          id="survey-status-filter-dropdown"
          show={isCategoryDropdownOpen}
          onToggle={(isOpen) => setIsCategoryDropdownOpen(isOpen)}
        >
          <Dropdown.Toggle>
            <div className="flex-center-center toggle-text-filter">
              {categoryFilter.length > 0 ? (
                <p className="dot-filter mr-10px"></p>
              ) : null}
              Filter by Category{" "}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sortStateSectionHideMenu">
            {clearAllFilters(categoryFilter, setCategoryFilter)}

            {renderallCategories()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  const renderallDuplicate = () => {
    let alltypes = ["duplicate", "non_duplicate", "both","minis"];
    return alltypes.map((e) => {
      return (
        <p
          className="single-status-filter"
          onClick={() => {
            setCurrentPage(1);
            setDuplicateFilter((prev) => {
              if (prev.includes(e)) {
                return prev.filter((item) => item !== e);
              } else {
                return [...prev, e];
              }
            });
          }}
        >
          {duplicateFilter.includes(e) ? (
            <FontAwesomeIcon
              className="mr-8px"
              icon={faCheck}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              className="mr-8px"
              style={{ visibility: "hidden" }}
              icon={faCheck}
            ></FontAwesomeIcon>
          )}
          {capsLetter(e).replaceAll("_", " ")}
        </p>
      );
    });
  };

  useEffect(() => {
    const flag = containsAll(
      queueTicketsData.map((e) => e.user_id),
      selected_tickets
    );

    setSelectAll(flag);
  }, [selected_tickets, queueTicketsData]);

  return (
    <div>
      <div
        className="flex-space-between-center"
        style={{
          gap: "12px",
        }}
      >
        <div className="flex-flex-start-center">
          <label
            className="mr-20px flex-center-center"
            onClick={(e) => {
              e.stopPropagation();
              if (e.target.checked) {
                setSelectedTickets([
                  ...selected_tickets,
                  ...queueTicketsData.map((e) => e.user_id),
                ]);
              } else {
                setSelectedTickets([
                  ...removeAllFromArray(
                    selected_tickets,
                    queueTicketsData.map((e) => e.user_id)
                  ),
                ]);
              }
            }}
          >
            <input
              checked={select_all}
              style={{ width: "16px", height: "16px" }}
              type="checkbox"
            />
          </label>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={action || undefined}
            onChange={(e) => {
              console.log(e);
              setAction(e);
            }}
            className="text-left"
            options={[
              {
                value: "SEND_MESSAGE",
                label: "Send Message",
              },
              {
                value: "CLOSED",
                label: "Closed",
              },
            ]}
          />
          <div
            style={{
              marginLeft: "5px",
            }}
          >
            <input
              type="text"
              value={search}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setSearch("");
                  get_all_tickets();
                } else if (!isNaN(value)) {
                  setSearch(parseInt(value));
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  get_all_tickets();
                }
              }}
              placeholder="Search by id"
              className="conversation-notes-input"
              style={{
                padding: "5px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            gap: "8px",
          }}
          className="flex-flex-start-center"
        >
          {filterByCategory()}
          {filterByDuplicate()}
          <div>
            <Dropdown
              className="survey-date-filter"
              id="survey-status-filter-dropdown"
              show={isStatusDropDownOpen}
              onToggle={(isOpen) => setIsStatusDropDownOpen(isOpen)}
            >
              <Dropdown.Toggle>
                <div className="flex-center-center toggle-text-filter">
                  {statusFilter.length > 0 ? (
                    <p className="dot-filter mr-10px"></p>
                  ) : null}
                  Filter by Status{" "}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="sortStateSectionHideMenu">
                {clearAllFilters(statusFilter, setStatusFilter)}
                {renderallStatus()}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {renderFilterByDateContent()}
          <div
            style={{ whiteSpace: "nowrap" }}
            className="flex-center-center font-weight-600"
          >
            Total Tickets : {totalPages}
          </div>
        </div>
      </div>
      <div className="mt-24px">
        <MaterialUITable
          columns={[
            {
              key: "user_id",
              formatter: (value) => {
                return (
                  <label
                    className=" width-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selected_tickets.includes(value)) {
                        setSelectedTickets([
                          ...removeAllItems(selected_tickets, value),
                        ]);
                      } else {
                        setSelectedTickets([...selected_tickets, value]);
                      }
                    }}
                  >
                    <input
                      style={{ width: "16px", height: "16px" }}
                      type="checkbox"
                      checked={selected_tickets.includes(value)}
                    />
                  </label>
                );
              },
            },
            {
              key: "user_id",
              label: "User Id",
            },
            {
              key: "user_name",
              label: "User Name",
            },
            {
              key: "pending_tickets",
              label: "Pending tickets",
            },
            {
              key: "first_ticket_date",
              label: "first Ticket date ",
              formatter: (value) => {
                return <div>{formatTimeStamp(value)}</div>;
              },
            },
            {
              key: "last_ticket_date",
              label: "Last ticket date",
              formatter: (value) => {
                return <div>{formatTimeStamp(value)}</div>;
              },
            },
            {
              key: "unique_ticket_categories",
              label: "Categories",
              formatter: (value) => {
                return <div>{render_category_remarks(value)}</div>;
              },
            },
            {
              key: "status_counts",
              label: "Remarks",
              formatter: (value) => {
                return <div>{render_remarks(value)}</div>;
              },
            },
          ]}
          data={queueTicketsData}
          rowClickHandler={(row) => {
            setSelectedQueueTicketId(row?.user_id);
            navigate(
              `/tickets?user_id=${
                row?.user_id
              }&status_filter=${statusFilter}&search=${search}&category_filter=${categoryFilter}&duplicate_filter=${duplicateFilter}&current_page=${currentPage}&date_range=${
                dateFilterText === "Filter Date Created"
                  ? ""
                  : `${dateFilterText}`
              }`
            );
          }}
          rowsShowPerPage={50}
          hidePagination={true}
        />
      </div>
      <div className="width-100 flex-center-center mt-24px">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalPages / 50)}
          onPageChange={(e) => setCurrentPage(e)}
        />
      </div>

      {end_chat_modal && (
        <EndChatModal
          show={end_chat_modal}
          onHide={() => {
            setEndChatModal(false);
            setAction("");
          }}
          apiCall={(object) => {
            make_bulk_options_api(object, true);
          }}
          bulk_tickets={true}
        />
      )}
      {send_message_modal && (
        <TextModal
          show={send_message_modal}
          onHide={() => {
            setSendMessageModal(false);
            setAction("");
          }}
          apiCall={(object) => {
            make_bulk_options_api(object, true);
          }}
          bulk_tickets={true}
        />
      )}
      {show_confirmation && (
        <ConfirmationModal1
          show={show_confirmation}
          onHide={() => {
            setShowConfirmation(false);
            setAction("");
          }}
          apiCall={() => {
            make_bulk_options_api(ticket_message?.object);
          }}
          message={ticket_message?.message}
        />
      )}
    </div>
  );
}
